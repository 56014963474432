<template>
   <div>
      <div class="row">
         <div class="col-xxl-12 mb-2">
            <!--retour sur liste = codeproduit + customer-->
            <!--retour sur actionView = soc + acl_id-->
            <router-link
               v-if="prevRoute && prevRoute.name === 'internship-notice.list'"
               :to="{
                  name: prevRoute.name,
                  params: {
                     customer_id: customer_id,
                     code_produit_id: code_produit_id,
                     selectParams: selectParams
                  }
               }"
               class="btn btn-sm btn-primary font-weight-bold text-uppercase px-lg-9 py-lg-3"
            >
               <i class="fas fa-arrow-left mr-2"></i>
               <span class="d-none d-sm-inline">{{ $t('GENERAL.BACK') }}</span>
            </router-link>
            <router-link
               v-else-if="prevRoute && prevRoute.name === 'action.view'"
               :to="{ path: prevRoute.path }"
               class="btn btn-sm btn-primary font-weight-bold text-uppercase px-lg-9 py-lg-3"
            >
               <i class="fas fa-arrow-left mr-2"></i>
               <span class="d-none d-sm-inline">{{ $t('GENERAL.BACK') }}</span>
            </router-link>
         </div>
      </div>
      <div class="row">
         <div class="col-xxl-4">
            <div class="card card-custom gutter-b">
               <div class="card-header border-0 pt-5 pb-5">
                  <h3 class="card-title align-items-start justify-content-center flex-column">
                     <span class="card-label font-weight-bolder">{{ $t('INTERNSHIP_NOTICE.DETAILS.INTERN_TABLE_TITLE') }}</span>
                  </h3>
               </div>
               <div class="card-body p-0">
                  <SkeletonTable :rows="6" :columns="4" v-if="loadingStagiaires" />
                  <b-table
                     v-else
                     outlined
                     hover
                     :items="stagiaires"
                     :fields="fieldsStagiaires"
                     :busy.sync="loadingStagiaires"
                     responsive
                     class="m-0 text-center"
                     select-mode="single"
                     ref="stagiairesTable"
                     @row-selected="onRowSelected"
                     selected-variant="primary"
                     selectable
                     striped
                  >
                     <template v-slot:cell(moyenne)="data"> {{ data.item.moyenne }}% </template>
                  </b-table>
               </div>
            </div>
         </div>
         <div class="col-xxl-8">
            <div class="card card-custom gutter-b">
               <div class="card-header border-0 py-2">
                  <h3 class="card-title align-items-center justify-content-between w-100" v-if="questions.length > 0">
                     <span class="card-label font-weight-bolder">{{
                        $t('INTERNSHIP_NOTICE.DETAILS.AUTHOR') + questions[0].stagiaireLastname + ' ' + questions[0].stagiaireFirstname
                     }}</span>
                     <a :href="questions[0].url" target="_blank">
                        <b-button variant="primary" class="btn" v-b-tooltip.hover="'Voir l\'avis de stage'">
                           <span>{{ $t('INTERNSHIP_NOTICE.DETAILS.SHOW') }}</span>
                        </b-button>
                     </a>
                  </h3>
                  <h3 class="card-title align-items-center justify-content-between w-100" v-else>
                     <span class="card-label font-weight-bolder">Veuillez sélectionner un stagiaire.</span>
                  </h3>
               </div>
               <div class="card-body p-0" v-if="questions.length > 0">
                  <b-table
                     outlined
                     hover
                     :items="questions[0].reponses"
                     :fields="fieldsQuestions"
                     responsive
                     class="m-0 text-center"
                     ref="questionsTable"
                     selected-variant="primary"
                     striped
                  >
                     <template v-slot:cell(sar_reponse)="data"> {{ data.item.sar_reponse }}% </template>
                  </b-table>
                  <div v-if="questions[0].pointFort || questions[0].pointFaible" class="d-flex justify-content-between mx-5 mt-4">
                     <div v-if="questions[0].pointFort" style="color: #327727" class="w-50 mr-3">
                        <p class="h4">Points forts :</p>
                        <p>"{{ questions[0].pointFort }}"</p>
                     </div>
                     <div v-if="questions[0].pointFaible" class="text-primary w-50 ml-3">
                        <p class="h4">Points faibles :</p>
                        <p>"{{ questions[0].pointFaible }}"</p>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</template>

<script>
import { SET_PAGETITLE } from '@/core/services/store/pagetitle.module';
import SkeletonTable from '@/view/content/skeleton/SkeletonTable.vue';
import { AVIS_STAGIAIRES } from '@/core/services/store/api-orion/avisStagiaires';

export default {
   props: {
      acl_id: {
         type: Number,
         required: true
      },
      acl_soc: {
         type: Number,
         required: true
      },
      customer_id: {
         type: Number,
         required: false
      },
      code_produit_id: {
         type: Number,
         required: false
      },
      selectParams: {
         type: Object,
         required: false
      }
   },
   beforeRouteEnter(to, from, next) {
      next((vm) => {
         vm.prevRoute = from;
      });
   },
   components: { SkeletonTable },
   data() {
      return {
         prevRoute: null,
         fieldsStagiaires: [
            { label: this.$t('GENERAL.LASTNAME'), key: 'lastname' },
            { label: this.$t('GENERAL.FIRSTNAME'), key: 'firstname' },
            { label: this.$t('INTERNSHIP_NOTICE.GENERAL.AVERAGE_NOTE'), key: 'moyenne', sortable: true },
            { label: this.$t('INTERNSHIP_NOTICE.DETAILS.FORMER_NAME'), key: 'formateurName' }
         ],
         stagiaires: [],
         loadingStagiaires: true,
         questions: [],
         fieldsQuestions: [
            { label: this.$t('INTERNSHIP_NOTICE.DETAILS.QUESTION'), key: 'question_txt', thClass: 'text-left', tdClass: 'text-left' },
            { label: this.$t('INTERNSHIP_NOTICE.GENERAL.TAUX'), key: 'sar_reponse', thClass: 'text-right', tdClass: 'text-right' }
         ],
         result: null,
         questionList: []
      };
   },
   async mounted() {
      this.setPageTitle();
      const params = {
         acl_id: this.acl_id,
         acl_soc: this.acl_soc
      };
      this.$store.dispatch(AVIS_STAGIAIRES, params).then((result) => {
         this.result = result.avis;
         this.questionList = result.questions;
         result.avis.forEach((item) => {
            this.stagiaires.push({
               id: item.stagiaireId,
               formateurId: item.formateurId,
               formateurName: item.formateurName,
               lastname: item.stagiaireLastname,
               firstname: item.stagiaireFirstname,
               moyenne: item.moyenne
            });
         });
         this.loadingStagiaires = false;
      });
   },
   methods: {
      setPageTitle() {
         this.$store.dispatch(SET_PAGETITLE, this.$t('MENU.INTERNSHIP_NOTICE'));
      },
      onRowSelected(selectedRow) {
         //add to question this.results where stagiaireId = selectedRows.id and formateurId = selectedRows.formateurId
         this.questions = [];
         if (selectedRow.length > 0) {
            this.result.forEach((item) => {
               if (item.stagiaireId === selectedRow[0].id && item.formateurId === selectedRow[0].formateurId) {
                  item.reponses.forEach((reponse) => {
                     reponse.question_txt = this.questionList[reponse.sar_question];
                  });
                  this.questions.push(item);
               }
            });
         }
      }
   }
};
</script>
